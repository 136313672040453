const ko = {
  network: {
    tips: "선택한 네트워크는 현재 지원되지 않습니다!Matic (Polygon) 네트워크를 선택해주세요!",
    ev: `MetaMask, Trust Wallet, imToken, TokenPocket 등의 지갑 소프트웨어 중 하나를 설치했는지 확인해주세요.`,
    copyight: process.env.VUE_APP_TITLE_NAME,
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `<h3><strong style="color:yellow">이 DAPP은 특정 국가 및 지역에서 법적 보호를 받지 못하므로, 사용 시 주의하시기 바랍니다!</strong></h3>
    <br/>
    <p><strong>존경하는 사용자 여러분:</strong></p>
<br/>
    <p>이 DAPP에 대한 관심과 지원에 감사드립니다. 여러분의 법적 권리를 보장하고 관련 법적 위험을 이해하도록 돕기 위해, 아래와 같은 중요한 공지를 드립니다.</p><br/>

    <p>이 DAPP은 암호화폐 투자 및 거래와 관련이 있으며, 일부 국가 및 지역에서는 관련 법률을 위반할 수 있습니다. 현지 법률 및 규제 당국의 정책에 따라, 이 DAPP은 특정 국가 및 지역에서 합법적으로 운영될 수 없으므로, 다음과 같이 명확히 선언합니다.</p>
<br/>
    <h3><strong>1. 서비스 범위 제한:</strong></h3><br/>
    <p style="color:yellow">이 DAPP은 아래 나열된 국가 및 지역에서 암호화폐 투자 및 거래 서비스를 제공하지 않으며, 기술 지원이나 사후 서비스를 제공하지 않습니다:</p><br/>
    <p style="color:yellow">중국(중국 본토, 홍콩 특별 행정구, 마카오 특별 행정구 및 대만 포함)/ 인도/ 알제리/ 방글라데시/ 모로코/ 이집트/ 네팔/ 파키스탄/ 카타르/ 이라크/ 튀니지/ 아프가니스탄/ 볼리비아.</p>
   <br/> <strong>위에 언급된 국가 및 지역에서 이 DAPP을 사용하여 암호화폐 투자 및 거래를 진행하는 사용자는 법적 위험을 피하기 위해 즉시 사용을 중단할 것을 권장합니다.</strong>
<br/><br/>
    <h3><strong>2. 법적 위험 및 투자 위험 경고:</strong></h3><br/>
    <p>위에 언급된 국가 및 지역에서 이 DAPP을 사용하여 암호화폐 투자 및 거래를 하는 경우, 심각한 법적 결과를 초래할 수 있습니다. 당 팀은 그러한 행위로 인해 발생하는 법적 책임을 지지 않습니다. 또한, 암호화폐 시장 자체는 매우 변동성이 크고 예측 불가능하며, 투자 활동에는 상당한 위험이 따릅니다. 이 DAPP을 사용하여 투자 및 거래를 계속할지 여부를 결정하기 전에 관련 위험을 충분히 이해하고 확인하시기 바랍니다.</p>
<br/>
    <h3><strong>3. 사용자 확인:</strong></h3><br/>
    <p>이 DAPP을 계속 사용하여 암호화폐 투자 및 거래를 진행하는 경우, 이 공지의 내용을 자세히 읽고 이해한 것으로 간주되며, 발생할 수 있는 법적 위험과 투자 위험을 인지하고 수용하는 것으로 간주됩니다. 사용자가 취하는 모든 행위는 관련 책임을 자발적으로 수용하는 것으로 간주되며, 운영팀은 이에 따른 결과에 대한 어떠한 책임도 지지 않습니다.</p>
<br/>
    <h3><strong>4. 운영팀 성명:</strong></h3><br/>
    <p>이 DAPP은 Bull Developer 팀에 의해 개발 및 운영됩니다. 우리는 항상 준법 경영의 원칙을 준수하며, 사용자에게 고품질의 서비스를 제공하기 위해 노력하고 있습니다. 그러나 특정 국가 및 지역의 특수한 법적 환경으로 인해 이러한 지역에서 암호화폐 관련 서비스를 계속 제공할 수 없습니다. 모든 사용자에게 이 DAPP을 사용하기 전에 거주하고 있는 국가 또는 지역의 법률 및 규정을 이해하고 준수할 것을 강력히 권장합니다. 법적 및 투자 위험을 피하기 위함입니다.</p>
<br/>
    <p><strong>다시 한번 여러분의 이해와 지원에 감사드리며, 추가 질문이나 지원이 필요하시면 공식 웹사이트를 통해 문의해 주시기 바랍니다.</strong></p>
<br/>
    <p><strong>감사합니다!</strong></p>
<br/>
    <p><strong>Bull Developer 팀</strong></p>`,
    protocol: `이 분산 애플리케이션(DApp)에는 불확실한 투자 위험이 내재하고 있습니다. 사용하기 전에, 리스크 경고와 개인정보처리방침을 반드시 주의 깊게 읽고 충분히 이해하십시오.`,
    more: "세부사항",
    media: "관련 소셜 미디어",
    stake: "2023년 11월 6일 전 세계 오픈, 텔레그램과 트위터를 팔로우하세요!",
    withdraw: "2023년 11월 6일 전 세계 오픈, 텔레그램과 트위터를 팔로우하세요!",
    Reinvest: "2023년 11월 6일 전 세계 오픈, 텔레그램과 트위터를 팔로우하세요!",
    Getreward:
      "2023년 11월 6일 전 세계 오픈, 텔레그램과 트위터를 팔로우하세요!",
    linkwallet: "지갑 연결",
    notification: "곧 Falcon 소각 가속 기능이 출시됩니다!",
    flashloansOK:'시작 성공! ',
    flashloansNo:'시작 실패',
    flashloansOnce:'1회 체험 기회를 얻게 되어 축하합니다',
    flashloansError:'죄송합니다, 체험 기회가 이미 사용되었습니다',
    falocnerror:'Falcon™ V2 테스트 자격이 승인되지 않았습니다!',
    falocninfo:'Falcon™ V2 레벨 승인을 받은 플레이어를 대상으로 합니다! 현재 공개 테스트 단계에 있습니다!!',
    falconv4:'Falcon™V4 차익 거래 클러스터가 베타 테스트 중입니다 (하루 28000회)!'
  },
  leavel: {
    0:"V0",
    1: "V1",
    2: "V2",
    3: "V3",
    4: "V4",
    5: "V5",
    6: "V6",
    7: "V7",
  },
  swap:{
    Swap:"교환",
    paynameA:"결제",
    paynameB:"수금",
    Approve:"승인",
    launchSwap:"교환 시작",
    recommend:"추천",
    swapError:"교환 오류",
    swapNetwork:"네트워크 오류",
  },
  top: {
    Account: "계정",
  },
  stake: {
    Balance: "당신의 잔액",
    Total: "총 스테이크",
    Appoove: "승인",
    Stake: "스테이크",
    Appooveing: "인증 중",
    AuthorSuccessful: "인증 성공",
    AuthorFailed: "인증 실패",
    Stakeing: "입금 중",
    StakeSuccessful: "입금 성공",
    StakeFailed: "입금 실패",
    StakeInfo: "미만일 수 없습니다:",
    Activeaccount:"계정 활성화",
    active:"계정"
  },
  menu: {
    Home: "홈",
    Language: "언어",
    Document: "백서",
    Contract: "계약",
    Watch: "시계",
    Falcon:"Falcon™",
    Coming: "곧 개봉",
    EvDao: "BULLDAO",
    Flashloan: "플래시론 시작",
    Developer:"개발자 문서",
    Launch:"Launch",
    xNumber:"남은",
    xTips:"자동 처리",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"Falcon™ V3 글로벌 공개 테스팅이 시작됩니다 (일일 요청 1000~7000회), 해당 차익 거래 계약에 대한 승인이 필요합니다!",
    Appoove2:"Auto",
    Ido:"Falcon 구독",
    myIdo:"내 Falcon",
    interest:"수익",

  },
  ido:{
    select: "커뮤니티 선택",
    flashmonkey: "FlashMonkey",
    flashdragon: "FlashDrgon",
    fashFalcon: "Falcon",
    powermonger: "PowerMonger",
    fashfalcon2: "FalconV2",
    other: "Other",
    Getmax: "최대구독금액받기",
    nowbuy: "지금구독",
    mynumber: "내구독총계",
    myrelease: "출시",
    waitrelease: "출시대기중",
    get: "출금",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "조직",
    endTimes:'첫 번째 라운드 카운트다운',
    getmax:'최대구독금액받기',
    Authorization:"권한 부여",
    Subscribe:"지금 구독",
    okSubscribe:"구독됨",
    message:"노드 오류, 노드를 변경하십시오",
    end1:"첫 번째 라운드 종료",
    needStart:"시작까지 카운트다운",
    getscusse:"가져 오기 성공",
    geterror:"획득 실패"

  },
  Withdraw: {
    Withdraw: "인출",
    Withdrawable: "인출 가능",
    Earnings: "수익",
    Brokerage: "브로커리지 금액",
    Reinvest: "재투자",
    Getreward: "보상 받기",
    Processing: "처리 중",
    unsuccessfule: "성공하지 못한",
    successful: "시스템 업데이트 중!",
    WithdrawInfo1: "인출 수량을 입력해 주세요",
    WithdrawInfo2: "수량이 부족합니다",
    burning:"FalconBurn",
    noBurn: "No Burn",
    addBurn: "추가 수익",
    addBurnCost: "추가 비용",
    BurnCount: "소각 계정",
    BurnSpeed: "소각 가속",
    needpay: "지불 필요",
    startBurn: "가속 시작",
    Burning: "가속 중"
  },
  Team: {
    innver: "Innver",
    Teamstaked: "팀 스테이크",
    leavel: "레벨",
    Team: "팀",
    Bind: "관계 바인딩",
    info: "바인딩 실패！",
    Binding: "바인딩 중",
    BindingSuccessful: "바인딩 성공",
    BindingFailed: "바인딩 실패",
    Bindinfo: "초대인의 지갑 주소를 입력하세요",
  },
  Share: {
    Invitelink: "초대 링크",
    Intips:
      "Dapp 초대장은 현재 사용할 수 없습니다. 최신 커뮤니티 업데이트를 기다려 주세요.",
    copyTitle: "초대 링크",
    copyText: "초대 링크 복사 성공!",
    copyCommnad: "링크 복사",
  },
  Contract: {
    contractaddress: "계약 주소",
    Copylink: "Polygonscan",
    CopylinkText: "polygonscan으로 가기!",
  },
  Wathch: {
    watchonlywallet: "시계 전용 지갑",
    addwallet: "시계 전용 지갑 추가",
    add: "추가",
    Leavel: "레벨",
    share: "공유",
    fnOpen: "이 지갑은 이 기능에 아직 초대되지 않았습니다!",
    nullString: "지갑 주소를 입력하세요!",
    addok:"추가 성공",
    addError:"추가 실패",
    addisExists:"이 주소는 이미 존재합니다",
    addlist:"관찰 목록",
  },
};
export default ko;
