<template>
    <div class="stakewait">
        <!-- notification start -->
        <div class="marquee">
            <Marquee :duration="15">{{ $t("network.falconv4") }}</Marquee>
            <!-- <Marquee :duration="15">{{ $t("menu.LogiV3tips") }}</Marquee> -->
        </div>
        <!-- notification end -->
        <div class="coinInfomation">
            <div class="coinImg"><img :src="falocn" class="dailogo" /></div>
            <div class="coinInfo">{{ $t("menu.LogiV4") }}</div>
        </div>
        <Divider style="background: rgba(142, 200, 243, 0.1)" />
        <div class="ownwalletInfo">
            <div class="ownTips">{{ $t("stake.Balance") }}:</div>
            <div class="ownMoney">
                {{ formattedDaiOwnMoney }}<img class="daimi" :src="daimni" />
            </div>
        </div>
        <div class="inputInfo">
            <input v-model.number="daiValue" type="number" class="inputStyle" @input="validateAmount"
                style="width: 58.66667vw;" />
            <button @click="(daiValue = formattedDaiOwnMoney), validateAmount()" class="tbnStyle ripple">
                {{ $t("network.all") }}
            </button>
        </div>
        <div class="ownwalletInfo">
            <div class="ownTips">{{ $t("menu.Contract") }}:</div>

        </div>
        <div class="inputInfo">
            <input class="inputStyle" />

        </div>

        <!-- <div class="autoProcess">
              <div class="a1">
                  <input type="checkbox" checked="checked">
              </div>
              <div class="a2">
                  <span> {{ $t("menu.xNumber") }}: {{ 0 }} ，{{ $t("menu.xTips") }}</span>
              </div>
          </div> -->

        <div class="StakeAuthor">
            <div class="Author">
                <!-- appoove button start-->
                <button class="StakeBtn ripple">
                    {{ $t("stake.Appoove") }}
                </button>
                <!-- <button class="StakeBtn" v-if="typeApprove == 1">
                      {{ $t("stake.Appooveing") }}
                  </button> -->
                <!-- appoove button end -->
            </div>

            <div class="Stakeing">
                <button class="StakeBtn ripple">

                    {{ $t("menu.Appoove2") }}
                </button>

            </div>
        </div>

        <div><img class="bStyle" :src="stylea" alt="" /></div>
        <!--loading start-->
        <div v-if="isloading" class="loading">
            <img :src="loadingimg" class="loadimg" />
        </div>
        <!--loading end-->
    </div>
</template>
<style scoped src="@/assets/css/EvFalocnV4.css"></style>
<style lang="less" scoped>
 .time_c{
    text-align: center;
    font-size: 18px;
    margin-top: 30px;
  }
  .nav {
  width: 100%;
  border-radius: 6px;
  margin: 0 auto;
}
.nav ul {
  width: 330px;
  margin: 0 auto;
  list-style: none;
  display: flex;
  justify-content: space-around;
}
.nav ul li {
  flex: 1;
}
.nav ul li .nav_c {
  color: #808383;
  font-family: "Poppins", sans-serif;
  padding: 8px;
  border-radius: 0px;
  background: #120245;
  border: 2px solid #05080b;
  display: block;
  text-align: center;
}
.nav ul li .nav_c_type {
  color: aqua;
  font-family: "Poppins", sans-serif;
  padding: 8px;
  font-weight: 800;
  border-radius: 5px;
  background: linear-gradient(to bottom,#5e0463,rgba(44,144,232,0.603));
  color: white;
  border: 2px solid #05080b;
  display: block;
}
.ownwalletInfo {
  font-family: "Poppins", sans-serif;
  display: flex;
}
.ownwalletInfo .ownTips {
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}
.ownwalletInfo .ownMoney {
  font-size: 20px;
}
.ownwalletInfo .ownMoney img.daimi {
  width: 18px;
  height: 18px;
  margin-left: 5px;
}
.ownwalletInfo .ownMoney img.swap_falcon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
}

</style>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import FG1 from "@/assets/img/FG1.svg";
import falocn from "@/assets/img/FalconLogoNew.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import Falocnn from "@/plugins/Falocnn.js";
import Web3 from "web3";
import axios from "axios";
import sign from "@/plugins/sign.js";

export default {
    data() {
        return {
            isButtonDisabled: true,
            depositDisabled: true,
            typeApprove: 0,
            typedeposit: 0,
            dai: dai,
            FG1: FG1,
            falocn: falocn,
            daimni: daimni,
            loadingimg: loadingimg,
            daiValue: "100",
            daiOwnMoney: 0,
            stylea: stylea,
            stakedMoney: 0,
            currentProgress: 0,
            usersdata: {},
            sitedata: {},
            addresscode: "",
            empowerbutton: true,
            needinvest: "",
            Minimal: 0,
            isloading: false,
            falconAddresstype: true,
            isOpentype: false,
            flashAddresstype: true,
            returndatadata: "",
            promptvalue: "",
            honorLeave: ""
        };
    },
    components: {
        Marquee,
        //ProgressBar,
    },
    async mounted() {

        // Determine whether the wallet is connected
        if (typeof window.ethereum !== "undefined") {
            //console.log("Connected wallet");

            // Async obtaining network information
            window.ethereum
                .request({ method: "eth_chainId" })
                .then((chainId) => {
                    // is Polygon?
                    if (chainId === "0x89") {
                        // console.log("The current network is a Polygon chain");
                        // this.$router.push({path:'/EvStake'});
                    } else {
                        //  console.log("The current network is not Polygon chain");
                        this.$router.push({ path: "/" });
                        return;
                    }
                })
                .catch((error) => {
                    console.error(
                        "Failed to obtain network information. Procedure:",
                        error
                    );
                });
        } else {
            //console.log("Unconnected wallet");
        }
        this.sitedata = siteapi;
        const currentAddress = await web3Utils.connectWallet();
        // console.log(currentAddress);
        this.addresscode = currentAddress;
        if (currentAddress) {
            this.daiOwnMoney = parseFloat(
                await web3Utils.getDaiBalance(this.addresscode)
            );

            //  console.log(this.daiOwnMoney.toFixed(6));

            this.signtype();
            // this.exchangeallowance()
        }
    },
    methods: {
        // 签名验证
        async signtype() {
            this.isloading = true;
            await sign.getauthorization(this.addresscode).then((res) => {

                if (res.code == 0) {
                    this.users();
                    this.exchangeallowance();
                    this.limitAllInvestAmount();
                    this.minInvestAmount();
                    this._falconAddress();
                    this.isOpen();
                    // this._flashAddress()
                }
                this.isloading = false;
            });
        },
        async _falconAddress() {
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.Falocnn_token.address;
            await Falocnn._falconAddress(
                this.addresscode,
                ContractAddress,
                _decimals
            ).then((res) => {
                // console.log(res, "_falconAddress");
                this.falconAddresstype = res.data;
            });
        },
        async isOpen() {
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.Falocnn_token.address;
            await Falocnn.isOpen(this.addresscode, ContractAddress, _decimals).then(
                (res) => {
                    // console.log(res, "isOpen");
                    this.isOpentype = res.data;

                }
            );
        },
        async _flashAddress() {
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.Falocnn_token.address;
            await Falocnn._flashAddress(
                this.addresscode,
                ContractAddress,
                _decimals
            ).then((res) => {
                // console.log(res, "_flashAddress");
                this.flashAddresstype = res.data;
                if (this.flashAddresstype) {
                    this.promptvalue = this.$t("network.falocnerror");
                } else {
                    this.promptvalue = this.$t("network.falocninfo");
                }
            });
        },
        async getdata() {
            const currentAddress = await web3Utils.connectWallet();
            // console.log(currentAddress);
            this.addresscode = currentAddress;
            if (currentAddress) {
                this.daiOwnMoney = parseFloat(
                    await web3Utils.getDaiBalance(this.addresscode)
                );

                //console.log(this.daiOwnMoney.toFixed(6));
                this.users();
                this.limitAllInvestAmount();
                this.minInvestAmount();
                //this.exchangeallowance()
            }
        },
        validateAmount() {

            if (this.daiValue * 1 < this.formattedDaiOwnMoney) {
                // this.isButtonDisabled = true;
                // this.depositDisabled = true;
            } else {
                // this.isButtonDisabled = false;
                // this.depositDisabled = false;
                this.daiValue = this.formattedDaiOwnMoney;
                this.exchangeallowance();
            }
        },
        // Authorization button
        empower() {
            this.isloading = true; // open loading
            this.Approve();
        },
        // contract Approve
        async Approve() {
            let _decimals = 18;
            let Address;
            let spender;
            Address = this.sitedata.dai_token.address;
            spender = this.sitedata.Falocnn_token.address;
            this.typeApprove = 1; // Approveing
            try {
                await Plugens.approveTokens(
                    Address, //
                    this.daiValue, // DAI number
                    this.addresscode,
                    spender,
                    _decimals
                ).then((res) => {
                    // console.log(res);
                    this.typeApprove = 0;
                    if (res.code == 0) {
                        this.typeApprove = 0;
                        this.$Notice.success({
                            title: this.$t("stake.AuthorSuccessful"),
                            // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
                        });
                        this.isloading = false; // close loading
                        this.empowerbutton = true;
                        this.validateAmount();
                    } else {
                        // this.typeApprove = 1;
                        this.$Notice.warning({
                            title: this.$t("stake.AuthorFailed"),
                            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                        });
                        this.isloading = false;
                    }
                });
            } catch (error) {
                // this.typeApprove = 1;
                this.isloading = false;
                this.$Notice.warning({
                    title: this.$t("stake.AuthorFailed"),
                    // desc: this.$t("stake.AuthorFailed"),
                });
                // console.log(error);
            }
        },
        // View the number of authorized contracts
        async exchangeallowance() {
            let _decimals = 18;
            let spender;
            let Address;
            Address = this.sitedata.dai_token.address;
            spender = this.sitedata.Falocnn_token.address;
            await Plugens.allowancetoken(
                Address,
                0,
                this.addresscode,
                spender,
                _decimals
            ).then((result) => {
                // console.log(result, "授权数量");
                if (result.data * 1 > 0) {
                    this.empowerbutton = true;
                } else {
                    this.empowerbutton = false;
                }
                // console.log(this.daiValue <= result.data * 1, "授权数量授权数量");
                if (this.daiValue <= result.data * 1) {
                    this.depositDisabled = false;
                } else {
                    this.depositDisabled = true;
                }
            });
        },
        // Contract deposit
        async deposit() {
            let _decimals = 18;
            let Address;
            Address = this.sitedata.aave_token.address;
            this.typedeposit = 1;
            try {
                await contract
                    .deposit(
                        Address, //Contract address
                        this.daiValue, //quantity
                        this.addresscode, //Current address
                        _decimals //Fractional part
                    )
                    .then((res) => {
                        // console.log(res);
                        this.typedeposit = 0;
                        this.isloading = false;
                        if (res.code == 0) {
                            this.typedeposit = 0;
                            this.$Notice.success({
                                title: this.$t("stake.StakeSuccessful"),
                                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                            });
                            this.users();
                            this.limitAllInvestAmount();
                            this.minInvestAmount();
                            this.getdata();
                        } else {
                            // this.typeApprove = 1;
                            this.isloading = false;
                            this.$Notice.warning({
                                title: this.$t("stake.StakeFailed"),
                                // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                            });
                        }
                    });
            } catch (error) {
                // this.typeApprove = 1;
                this.$Notice.warning({
                    title: this.$t("stake.StakeFailed"),
                    //desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
                // console.log(error);
            }
        },
        // Need investment
        async limitAllInvestAmount() {
            //  console.log(this.sitedata);
            let _decimals = 18;
            let ContractAddress;
            // console.log("Need investment");
            ContractAddress = this.sitedata.aave_token.address;
            await contract
                .limitAllInvestAmount(
                    this.addresscode, //Current user address
                    ContractAddress, //Call contract
                    _decimals
                )
                .then((res) => {
                    // console.log(res, "Need investment");
                    this.needinvest = (res.data * 1) / 1000000;
                    this.allInvestAmount(res.data);
                });
        },
        // invested
        async allInvestAmount(value) {
            // console.log(this.sitedata);
            let _decimals = 18;
            let ContractAddress;
            // console.log("invested");
            ContractAddress = this.sitedata.aave_token.address;
            await contract
                .allInvestAmount(
                    this.addresscode, //Current user address
                    ContractAddress, //Call contract
                    _decimals
                )
                .then((res) => {
                    //  console.log(res, "allInvestAmount");
                    const total = value * 1;
                    //  console.log(total);
                    this.currentProgress = ((res.data * 1) / total) * 100;
                    this.currentProgress = this.currentProgress.toFixed(2) * 1;
                    this.currentProgress = this.currentProgress - 2;
                });
        },
        // Minimum total input
        async minInvestAmount() {
            // console.log(this.sitedata);
            let _decimals = 18;
            let ContractAddress;
            // console.log("Minimum total input");
            ContractAddress = this.sitedata.aave_token.address;
            await contract
                .minInvestAmount(
                    this.addresscode, //Current user address
                    ContractAddress, //Call contract
                    _decimals
                )
                .then((res) => {
                    //      console.log(res, "Minimum total input");
                    this.Minimal = res.data;
                });
        },
        // User information
        async users() {
            //console.log(this.sitedata);
            let _decimals = 18;
            let ContractAddress;
            //console.log("User information");
            ContractAddress = this.sitedata.aave_token.address;
            await contract
                .users(
                    this.addresscode, //Current user address
                    ContractAddress, //Call contract
                    _decimals
                )
                .then((res) => {
                    // console.log(res.data,'levellevellevel')
                    this.honorLeave = res.data.level

                    //console.log(res, "User information");
                    this.usersdata = res.data;
                    this.stakedMoney = res.data.investAmount * 1;
                    //console.log(this.stakedMoney);
                    //const total = 14000;
                    //this.currentProgress = (this.stakedMoney / total) * 100;
                });
        },
        // tb地址接口
        getindex() {
            this.isloading = true; // open loading
            var datas = {
                address: this.addresscode,
                amount: this.daiValue,
                lever: this.honorLeave.toString()
            }

            let jsonData = JSON.stringify(datas);


            var base64 = window.btoa(jsonData)


            axios
                .get(
                    this.sitedata.url.address + "/api/index/falcon?data=" +
                    base64,
                    {}
                )
                .then((res) => {

                    this.returndatadata = res.data.data;
                    this.stake();
                });
        },

        // Stake button
        async stake() {
            let _decimals = 18;
            let ContractAddress;
            ContractAddress = this.sitedata.Falocnn_token.address;
            try {
                await Falocnn.falcon(
                    this.addresscode,
                    ContractAddress,
                    this.returndatadata,
                    _decimals
                ).then((res) => {
                    // console.log(res, "falcon");
                    if (res.code == 0) {
                        this.isloading = false;
                        this._falconAddress();
                        this.isOpen();
                        // this._flashAddress()
                        this.$Notice.success({
                            title: this.$t("network.flashloansOK"),
                            //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
                        });
                    } else {
                        this.isloading = false;
                        this.$Notice.warning({
                            title: this.$t("network.flashloansNo"),
                            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                        });
                    }
                });
            } catch (error) {
                // this.typeApprove = 1;
                this.isloading = false;
                this.$Notice.warning({
                    title: this.$t("network.flashloansNo"),
                    //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
                });
                //console.log(error);
            }
        },


        warning(nodesc) {
            this.$Notice.warning({
                title: nodesc ? "" : this.$t("network.stake"),
                //desc: nodesc ? "" : this.$t("network.stake"),
            });
        },
    },
    computed: {
        formattedDaiOwnMoney() {
            if (!this.daiOwnMoney) return "0.0000";

            const multipliedNumber = this.daiOwnMoney * 100000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 100000;
            //    console.log(result);
            //    console.log(this.daiOwnMoney);
            let value = parseFloat(result);


            return value;
        },
        formattedDaiOwnMoney2() {
            if (!this.stakedMoney) return "0.0000";
            const multipliedNumber = this.stakedMoney * 100000;
            const truncatedNumber = Math.trunc(multipliedNumber);
            const result = truncatedNumber / 100000;
            //console.log(result);
            //     console.log(this.stakedMoney);
            let value = parseFloat(result);
            //console.log(value)
            return value;
        },
    },
};
</script>
